import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

class ExpPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <div>
            Sorry, this page is being worked on
        </div>
      </div>
    )
  }
}

export default ExpPage;
