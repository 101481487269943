import React from 'react';
import {Bar} from 'react-chartjs-2';
import Chart from 'chart.js/auto'

const SkillChart = ({title, list, listData}) => {
    const skillsData = {
        labels: list,
        datasets: [{
            label: title,
            data: listData,
            backgroundColor:[
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(201, 203, 207, 0.2)'
            ],
            borderColor:[
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)'
            ],
            borderWidth: 1
        }]
    };

    const options = {
        scales: {
            y: {
                ticks: {
                    display: false // Hide y-axis ticks
                }
            }
        },
    };

    return <Bar data={skillsData} options={options}/>
}

export default SkillChart